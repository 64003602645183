<template>
	<div class="mainTem">
		<el-tabs v-model="detTabActiveName" type="border-card">
			<el-tab-pane label="" name="first" v-loading="loading">
				<span slot="label"><i class="el-icon-document"></i> <span>{{$t('i18nn_e6a250894a04db4c')}}</span></span>
				<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_868e09fc83c854d1')}}</h3></div> -->
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">{{ $t('i18nn_868e09fc83c854d1') }}</span>
								<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div>

				<div class="buyerTableCon" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
					<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
						v-loading="loading">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<!-- <el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column> -->

						<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
							<template slot-scope="scope">
								<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="success"
									v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="warning"
									v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="danger"
									v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							</template>
						</el-table-column>

						<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
						<!-- <el-table-column prop="goodsSku" :label="$t('i18nn_c6e98c83801b5198')">
					<template slot-scope="scope">
						<div v-if="scope.row.mySkuDto">
							{{ scope.row.mySkuDto.goodsName }}
							<br />
							{{ scope.row.mySkuDto.goodsNameEn }}
						</div>
					</template>
				</el-table-column> -->
						<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')"></el-table-column>
						<el-table-column prop="signQuantity" :label="$t('i18nn_b1467e06606a8471')"></el-table-column>
						<!-- <el-table-column prop="tracking" :label="$t('i18nn_3e764f7ae7f5e850')"></el-table-column> -->
						<el-table-column prop="relationOrderNo" :label="$t('i18nn_158db9252e21d1a6')"></el-table-column>

						<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')" show-overflow-tooltip></el-table-column> -->

						<el-table-column prop="inWhStatusName" :label="$t('i18nn_e4342b5989a0c5ac')"></el-table-column>
						<el-table-column prop="inWhDate" :label="$t('i18nn_9eaa906ebc446062')"></el-table-column>

						<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

						<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
							<template slot-scope="scope">
								<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
								<div v-if="'10' == scope.row.status||'0' == scope.row.status">
									<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
									<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
										icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>

					<!-- <div class="tableCon_pagination">
        <hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
      </div> -->
				</div>
			</el-tab-pane>
			<el-tab-pane label="" name="second">
				<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_d7315fb8114eb446')}}</span></span>

				<FeeModelById :id="detId"></FeeModelById>
			</el-tab-pane>
		</el-tabs>
		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
	</div>
</template>
<script>
	import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isSel:{
			//   default: function() {
			//     return false
			//   },
			//   type: Boolean
			// },
			detId: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		components: {
			FeeModelById
		},
		data() {
			return {
				detTabActiveName: 'first',
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,
				// form: {
				//     "goodsSku": "",//this.$t('i18nn_31f887c1ada79a99'),
				//     "cusCode":"",//this.$t('i18nn_64fed2851f79d875'),
				//     "goodsName":"",//this.$t('i18nn_b23004db5349dfd2'),
				//     "goodsNameEn":"",//this.$t('i18nn_05141ac0139eaf01'),
				//     "goodsImg":"",//this.$t('i18nn_877c5a0e44a0eb07'),
				//     "defCode":"",//this.$t('i18nn_791e36963e37617a'),
				//     "hashCode":"",//this.$t('i18nn_198738759379a3c0'),
				//     "declareNameCh":"",//this.$t('i18nn_73d237ea940222ce'),
				//     "declareNameEn":"",//this.$t('i18nn_9894786aad05f849'),
				//     "declarePrice":"",//this.$t('i18nn_fe28507421c4c3b3'),
				//     "goodsWeight":"",//this.$t('i18nn_11e41b2ec3d872ed'),
				//     "weightUnit":"",//this.$t('i18nn_8e01866868930d09'),
				//     "goodsLength":"",//this.$t('i18nn_2cba96917484569a'),
				//     "goodsWidth":"",//this.$t('i18nn_9e24e0e4745f4948'),
				//     "goodsHeight":"",//this.$t('i18nn_c5fa0857bc0df2d6'),
				//     "id":"",//"数据id"
				// },

				// formRules: {
				//   goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   cusCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsImg: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   defCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   hashCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declareNameCh: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declareNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   weightUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   volumeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   whFeeType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   whFeeAmt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				// selectOption: {
				//   wh_weight_unit: [],
				//   wh_vol_unit: [],
				//   wh_goods_fee_type:[],
				//   statusList: [
				//     {
				//       value: '',
				//       label: this.$t('i18nn_16853bda54120bf1')
				//     },
				//     {
				//       value: '1',
				//       label: this.$t('i18nn_fdd8426d7b8869c5')
				//     },
				//     {
				//       value: '2',
				//       label: this.$t('i18nn_619d7e563f879811')
				//     },
				//     {
				//       value: '3',
				//       label: this.$t('i18nn_1dfb3a065d3ffe39')
				//     }
				//   ]
				// },
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc' //desc降序，asc升序
					// "accountPeriod":"",
					// packId: "",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: '',
				}
			};
		},
		watch: {
			detId: function(newVal, oldVal) {
				console.log('watchKey');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				this.initData();
				// }
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				if (this.detId) {
					this.tableData = [];
					this.getPageData();
				}
				// this.getDicData();
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   this.dialogFormVisible = true;
			//   let form = Object.assign({}, formParm);
			//   console.log('form',form);
			//   // // 重置
			//   this.resetForm('form');
			//   if (null === formParm) { //新增

			//     this.form.weightUnit = '1';
			//     this.form.volumeUnit = '1';
			//     this.form.whFeeType = '1';

			//     this.dialogFormStatus = 0;
			//     // form.subUserId = null;
			//     // form.userSubUserId = null;
			//     // form.state = true;
			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   } else { //修改
			//     this.dialogFormStatus = 1;
			//     // form.state = form.state === '0' ? true : false;

			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   }
			//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//   //       type: 'warning',
			//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //     });
			// },
			//打开编辑
			// openEdit(event, row, index) {
			//   event.stopPropagation();
			//   this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },

			//删除
			delAction(event, row) {
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.$message({
						//   type: 'success',
						//   message: '删除成功!'
						// });
						this.delDataAction(event, row);
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);
				// let parm = [];

				//   if (!!row) {
				//     //单条
				//     parm = [row.id];
				//   } else {
				//     //多条
				// //     let dataList = this.multipleSelection;
				// //     if (dataList.length < 1) {
				// //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
				// //       return;
				// //     }

				// //     let dataListParm = dataList.map(v => v.id);
				// //     parm = dataListParm;
				//     // console.log('dataListParm', dataListParm);
				//     // let dataParm = {
				//     //   ids: dataListParm
				//     // };
				//   }

				this.postData(this.$urlConfig.WhExchangeReturnDel + '/' + row.id, {}, () => {
					this.initData();
					//  this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('tips.tipsTitle'), {
					//   type: 'success',
					//   confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					// });
					this.$message.success('删除成功！');
				});
			},

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);

			//       } else {

			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//提交信息
			postData(url, formData) {
				// let _this = this;
				this.loading = true;

				// formData.state = formData.state ? '0' : '1';
				this.$http.delete(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('tips.submitSuccess'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							this.dialogFormVisible = false;
							this.getPageData();
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success',
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
						} else {
							// if (!data.msg) {
							//   data.msg = this.$t('tips.submitError');
							// }
							this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
								type: 'warning',
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t("tips.submitError"));
						this.loading = false;
						this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
							type: 'warning',
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//查询数据
			serPageData() {
				// this.pagination.current_page = 1;
				this.getPageData();
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.WhExchangeReturnRecDet + '/' + this.detId + '/record', {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			}
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event,row) {
			//   event.stopPropagation();
			//   this.$emit('selectRow',row)
			// },
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_weight_unit', 'wh_vol_unit','wh_goods_fee_type'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			//         this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			//         this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			//       } else {
			//         if (!data.msg) {
			//           data.msg = this.$t("tips.submitError");
			//         }
			//         this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {

		// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
	}
</style>