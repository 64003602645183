<template>
	<!-- <el-dialog :title="$t('i18nn_7c3fc1953013745c')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_7c3fc1953013745c')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="1200px">
		<div class="">
			<!--本页切换列表-->
			<div>
				<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
					<div class="tableConTable">
						<div>
							<el-card shadow="never" style="margin-bottom: 10px;">
								<el-descriptions :title="''">
									<el-descriptions-item
										:label="$t('i18nn_b0c50b6ff9674173')">{{filterData.carrier}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_581bc71c027386cb')">{{filterData.tracking}}</el-descriptions-item>
								</el-descriptions>
							</el-card>

							<div v-if="'UPS'==filterData.carrier">
								<!-- <LgsUpsTrackSimTem :openTime="upsData.inquiryNumber" :itemData="upsData"></LgsUpsTrackSimTem> -->
								<LgsUpsTrackSimTem :openTime="upsData.inquiryNumber" :itemData="upsData"></LgsUpsTrackSimTem>
							</div>
							<div v-else>
								<!-- <LgsFedexTrackTem :openTime="fedexData.trackingNumber" :itemData="fedexData"></LgsFedexTrackTem> -->
								<LgsFedexTrackSimTem :openTime="fedexData.trackingNumber" :itemData="fedexData"></LgsFedexTrackSimTem>
							</div>
						</div>

					</div>
					<!-- <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div> -->
				</div>
			</div>


		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import LgsFedexTrackSimTem from '@/components/StorageCenter/ExpressSheet/LgsFedexTrackSimTem.vue';
	import LgsUpsTrackSimTem from '@/components/StorageCenter/ExpressSheet/LgsUpsTrackSimTem.vue';
	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			LgsFedexTrackSimTem,
			LgsUpsTrackSimTem
		},
		data() {
			return {
				dialogFile: false,
				loading_load: false,

				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				fedexData: {},

				upsData: {},

				dataOpenTime: "",

				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 300],
				// 	page_size: 200,
				// 	total: 0
				// },

				selectOption: {
					wh_no: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// orderNumber: '',
					"id": null, //"数据ID",
					"carrier": null, //this.$t('i18nn_2279b6465da730e9'),
					"tracking": null, //this.$t('i18nn_4531476fa35570f0')
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			console.log(' activated');
		},
		//创建时
		created() {
			console.log(' created');
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {

			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				// this.filterData.id = this.row.id;
				this.filterData.carrier = "";
				this.filterData.tracking = this.row.tracking;
				
				//有渠道
				if (this.row && !!this.row.carrier) {
					this.filterData.carrier = this.filterData.carrier.toUpperCase();
				} else {//只有单号
					this.filterData.carrier = '';
					if (this.filterData.tracking.indexOf('1Z')>-1) {
						this.filterData.carrier = 'UPS';
					} else if(this.filterData.tracking.length==12){
						this.filterData.carrier = 'FEDEX';
					}
				}
				// this.filterData.carrier = this.row.carrier;

				this.upsData = {};
				this.fedexData = {};
				if('FEDEX'==this.filterData.carrier){
					this.getFedexData(this.filterData.tracking);
				} else if('UPS'==this.filterData.carrier){
					this.getUpsData(this.filterData.tracking);
				}
				// this.getTrackingData();
			},

			// goBack() {

			// },
			// formatterDate(DateStr) {
			// 	if (DateStr) {
			// 		return this.$Utils.fomatterDate_YYYYMMddhms(DateStr);
			// 	} else {
			// 		return "";
			// 	}
			// },

			//分页的筛选项数据
			// pageFilterData() {
			// 	return {
			// 		"id": this.filterData.id, //"数据ID",
			// 		"carrier": this.filterData.carrier, //this.$t('i18nn_2279b6465da730e9'),
			// 		"tracking": this.filterData.tracking, //this.$t('i18nn_4531476fa35570f0')
			// 	};
			// },

			//请求分页数据
			getFedexData(tracking) {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhFedexLgsTrackQuery, [tracking])
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code && data.rows && data.rows[0]) {
							this.fedexData = data.rows[0];
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求分页数据
			getUpsData(tracking) {
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.lgsUpsTracking + "?trackNo=" + tracking, {})
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code && data.data) {
							this.upsData = data.data;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			// //请求分页数据
			// getTrackingData() {

			// 	let filterData = {
			// 		"id": this.filterData.id, //"数据ID",
			// 		"carrier": this.filterData.carrier, //this.$t('i18nn_2279b6465da730e9'),
			// 		"tracking": this.filterData.tracking, //this.$t('i18nn_4531476fa35570f0')
			// 	};

			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhExpLgsTrackList, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				//表格显示数据
			// 				// this.tableData = data.data.trackResults[0];
			// 				// this.fedexData = data.data;
			// 				if ('UPS' == this.filterData.carrier) {
			// 					this.upsData = data.data;
			// 				} else {
			// 					this.fedexData = data.data;
			// 				}
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading_load = false;
			// 		});
			// },
			// getUpsData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.lgsUpsTracking + "?trackNo=" + this.filterData.tracking, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading_load = false;
			// 			//表格显示数据
			// 			this.upsData = data.data;
			// 			// this.openTime = new Date().getTime();
			// 			//当前数据总条数
			// 			// this.pagination.total = parseInt(data.total);
			// 			//当前页数
			// 			// this.pagination.current_page = parseInt(data.current);
			// 			//当前页条数
			// 			// this.pagination.page_size = parseInt(data.size);
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading_load = false;
			// 		});
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>